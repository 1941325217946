import "../index.css";
import React from 'react';
import ReactPlayer from 'react-player';

let videosrc = "/videos/dce_podcast_ep_001.mp4";
let episode_002 = "/videos/dce_podcast_ep_002.mov";
let episode_004 = "/videos/dce_podcast_ep_004.mov"

export default props => {
  return (
    <div class="video-container">
      <span class="video-header">Auf den Spuren des Messias - Folge 6</span>
      <iframe 
      src="https://drive.google.com/file/d/1m9xeszCYfgKJlwlPTIWHlbOt6SbodbGR/preview" 
      width='420px' 
      height='380px'
      allowFullScreen 
      allow="autoplay">
      </iframe>
      <span class="video-header">Auf den Spuren des Messias - Folge 5</span>
      <iframe 
      src="https://drive.google.com/file/d/1KpqbkgoApE-iyA1DzLAClVafrgeMLOJr/preview" 
      width='420px' 
      height="380px"
      allowFullScreen 
      allow="autoplay">
      </iframe>
      <span class="video-header">Auf den Spuren des Messias - Folge 4</span>
      <ReactPlayer
          className='react-player fixed-center'
          url={episode_004}
          width='80%'
          height='60%'
          controls = {true}
          />
      <span class="video-header">Auf den Spuren des Messias - Folge 3</span>
      <iframe 
        src="https://drive.google.com/file/d/1Vf_bnBG2KpPMaZHW-z-RDHZcBBXxrejh/preview" 
        width='420px' 
        height="380px"
        allowFullScreen 
        allow="autoplay">
      </iframe>
      <span class="video-header">Auf den Spuren des Messias - Folge 2</span>
      <ReactPlayer
          className='react-player fixed-center'
          url={episode_002}
          width='80%'
          height='60%'
          controls = {true}
          />
      <span class="video-header">Auf den Spuren des Messias - Folge 1</span>
      <ReactPlayer
          className='react-player fixed-center'
          url={videosrc}
          width='80%'
          height='60%'
          controls = {true}
          />
      <span><br></br><br></br><br></br>Favicon von <a target="_blank" href="https://icons8.com/icon/0LKlS9gmWX8B/menorah">Menorah</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></span>
    </div>
    
  );
};








  /**<Player
        playsInline
        poster="../public/assets/image/logo192.png"
        src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
        width="100vw"
        height="80vh"
      /> */